import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Assets
import CloseIcon from 'assets/images/x.svg'

// Store
import { actions } from 'core/store'

// Styled Elements
import {
  CreateIndividualCaseDrawerWrapper,
  DrawerHeader,
  DrawerContent,
  DrawerFooter,
  DrawerCloseIcon,
  DrawerTitle,
} from './CreateIndividualCaseDrawer.elements'

// Views
import { Drawer, Image, Button } from 'views/components'

import { CreateIndividualCaseForm } from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

const CreateIndividualCaseDrawer = (props) => {
  // Destructure
  const { ui, actions } = props

  //  Store State
  const { isCreateIndividualCaseDrawerOpen } = ui

  // Store Actions
  const { closeCreateIndividualCaseDrawer } = actions

  // Refs
  const formRef = useRef()

  // Functions
  const handleButtonClickSubmit = () => {
    if (formRef.current) {
      formRef.current.submitForm()
    }
  }

  const handleCloseDrawer = () => {
    closeCreateIndividualCaseDrawer()
  }

  return (
    <Drawer open={isCreateIndividualCaseDrawerOpen} anchor="right" onClose={() => {}}>
      <CreateIndividualCaseDrawerWrapper>
        <DrawerHeader>
          <DrawerCloseIcon onClick={handleCloseDrawer}>
            <Image width={20} height={20} src={CloseIcon} />
          </DrawerCloseIcon>
          <DrawerTitle>Create Individual Case</DrawerTitle>
        </DrawerHeader>
        <DrawerContent>
          <CreateIndividualCaseForm formRef={formRef} />
        </DrawerContent>
        <DrawerFooter>
          <Button onClick={handleButtonClickSubmit}>Create Case</Button>
        </DrawerFooter>
      </CreateIndividualCaseDrawerWrapper>
    </Drawer>
  )
}

// Default Props
CreateIndividualCaseDrawer.defaultProps = {
  ui: {},
  actions: {},
}

// Proptypes Validation
CreateIndividualCaseDrawer.propTypes = {
  ui: PropTypes.shape({ isCreateIndividualCaseDrawerOpen: PropTypes.bool }),
  actions: PropTypes.shape({ closeCreateIndividualCaseDrawer: PropTypes.func }),
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateIndividualCaseDrawer)
