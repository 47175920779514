import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Assets
import CloseIcon from 'assets/images/x.svg'

// Store
import { actions } from 'core/store'

// Styled Elements
import { ModalFooterWrapper, ModalBodyWrapper, ModalFooterButton } from './DeleteAddressModal.elements'

// Views
import { Modal, ModalHeader, ModalBody, ModalFooter, Image, Button } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

const DeleteAddressModal = (props) => {
  // Destructure
  const { ui, actions } = props

  // Store State
  const { isDeleteAddressModalOpen } = ui

  // Store Actions
  const { toggleDeleteAddressModal } = actions

  // Functions
  const toggleModal = () => {
    toggleDeleteAddressModal()
  }

  return (
    <Modal isOpen={isDeleteAddressModalOpen} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal} close={<Image width={20} height={20} src={CloseIcon} onClick={toggleModal} />}>
        Delete address
      </ModalHeader>
      <ModalBody>
        <ModalBodyWrapper>Are you sure you want to delete this address? This cannot be undone.</ModalBodyWrapper>
      </ModalBody>
      <ModalFooter>
        <ModalFooterWrapper>
          <ModalFooterButton>
            <Button color="secondary">Delete</Button>
          </ModalFooterButton>
          <ModalFooterButton>
            <Button color="default">Cancel</Button>
          </ModalFooterButton>
        </ModalFooterWrapper>
      </ModalFooter>
    </Modal>
  )
}

// Default Props
DeleteAddressModal.defaultProps = {
  ui: {},
  actions: {},
}

// Proptypes Validation
DeleteAddressModal.propTypes = {
  ui: PropTypes.shape({ isDeleteAddressModalOpen: PropTypes.bool }),
  actions: PropTypes.shape({
    toggleDeleteAddressModal: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAddressModal)
