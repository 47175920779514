import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash.debounce'
import { connect } from 'react-redux'

// Constants
import { CASE_STATUS_OPTIONS, CASE_TYPE_OPTIONS } from 'common/constants/formOptions'

// Store
import { actions } from 'core/store'

// Hooks
import { useFetchAutoCompleteCustomer } from 'core/hooks/api'

// Styled Elements
import {
  CaseManagementTableFilterFormWrapper,
  CaseManagementTableFilterInner,
  CaseManagementTableFilterItem,
} from './CaseManagementTableFilterForm.elements'

// Views
import { Select, AutoComplete } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = (dispatch) => actions(dispatch)

const CaseManagementTableFilterForm = (props) => {
  // Destructure
  const { onChange, onCustomerSearch } = props

  // States
  const [customerSearchOptions, setCustomerSearchOptions] = useState([])
  const [caseType, setCaseType] = useState()
  const [caseStatus, setCaseStatus] = useState()

  // Hooks
  const { autoCompleteCustomerData, getAutoCompleteCustomer } = useFetchAutoCompleteCustomer()

  // Functions
  const handleOnAutoCompleteSelect = ({ target }) => {
    onCustomerSearch(target.value)
  }

  const handleOnCaseTypeSelect = ({ target }) => {
    setCaseType(Number.isNaN(+target.value) ? undefined : +target.value)
  }

  const handleOnCaseStatusSelect = ({ target }) => {
    setCaseStatus(Number.isNaN(+target.value) ? undefined : +target.value)
  }

  const handleOnAutoCompleteInputChange = debounce((e) => {
    if (e) {
      const queryValue = e.target.value

      if (queryValue) getAutoCompleteCustomer({ customer_id: queryValue })
    }
  }, 500)

  // useEffect
  useEffect(() => {
    if (autoCompleteCustomerData) {
      setCustomerSearchOptions(() =>
        autoCompleteCustomerData.map(({ Customer_ID }) => ({ label: Customer_ID, value: Customer_ID }))
      )
    }
  }, [autoCompleteCustomerData])

  useEffect(() => {
    onChange({ caseType, caseStatus })
  }, [caseType, caseStatus])

  return (
    <CaseManagementTableFilterFormWrapper>
      <CaseManagementTableFilterInner>
        <CaseManagementTableFilterItem>
          <AutoComplete
            placeholder="Search Customer ID"
            onSelect={handleOnAutoCompleteSelect}
            onInputChange={handleOnAutoCompleteInputChange}
            options={customerSearchOptions}
            getOptionSelected={(option) => option.value}
            getOptionLabel={(option) => option.label || ''}
          />
        </CaseManagementTableFilterItem>
        <CaseManagementTableFilterItem>
          <Select
            placeholder="Case Type"
            name="caseType"
            onChange={handleOnCaseTypeSelect}
            options={CASE_TYPE_OPTIONS}
          />
        </CaseManagementTableFilterItem>
        <CaseManagementTableFilterItem>
          <Select
            placeholder="Status"
            name="caseStatus"
            onChange={handleOnCaseStatusSelect}
            options={CASE_STATUS_OPTIONS}
          />
        </CaseManagementTableFilterItem>
      </CaseManagementTableFilterInner>
    </CaseManagementTableFilterFormWrapper>
  )
}

// Default Props
CaseManagementTableFilterForm.defaultProps = {
  onChange: () => {},
  onCustomerSearch: () => {},
}

// Proptypes Validation
CaseManagementTableFilterForm.propTypes = {
  onChange: PropTypes.func,
  onCustomerSearch: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(CaseManagementTableFilterForm)
