import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'

// Library Components
import { Layout } from 'antd'

// Assets
import PersonsIcon from 'assets/images/icon-person.svg'

// Store
import { actions } from 'core/store'

// Hooks
import { useFetchCaseCustomer, useFetchAllCaseCountries } from 'core/hooks/api'

// Styled Components
import { CaseDetailsWrapper, TabHeaderIcon } from './Details.elements'

// Views
import { Container, Tabs, TabPane, Image, Button } from 'views/components'

import {
  App,
  Header,
  Sidebar,
  Content,
  CasesSubHeader,
  CaseDetailsProfileForm,
  CaseDetailsKYCForm,
} from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

const Details = (props) => {
  // Destructure
  const { form, actions } = props

  // Refs
  const formProfileRef = useRef()
  const formKYCRef = useRef()

  // Store State
  const { countryListOptions } = form

  // Store Actions
  const { setShowHeaderLoader, setCountryListOptions, setActiveModule } = actions

  // Hooks
  const { customerId } = useParams()
  const { allCaseCountriesData, getAllCaseCountries } = useFetchAllCaseCountries()
  const { caseCustomerData, getCaseCustomer } = useFetchCaseCustomer()
  const [isCaseUpdated, setIsCaseUpdated] = useState(false)
  const [caseDataState, setCaseDataState] = useState({})

  // Functions
  const handleProfileFormSubmitClick = () => {
    if (formProfileRef.current) {
      setShowHeaderLoader(true)
      formProfileRef.current.submitForm()
    }
  }

  const handleKYCFormSubmitClick = () => {
    if (formKYCRef.current) {
      formKYCRef.current.submitForm()
    }
  }

  const handleOnFormSubmit = () => {}

  // useEffect
  useEffect(() => {
    if (!countryListOptions.length) {
      getAllCaseCountries()
    }
    setActiveModule('details')
  }, [])

  useEffect(() => {
    if (customerId) {
      getCaseCustomer({ customer_id: customerId })
    }
  }, [customerId])

  // Hydrating page with customer case data
  useEffect(() => {
    if (caseCustomerData) {
      setCaseDataState(caseCustomerData)
    }
  }, [caseCustomerData])

  // Requesting customer case data after save
  useEffect(() => {
    if (isCaseUpdated) {
      getCaseCustomer({ customer_id: customerId })
      setIsCaseUpdated(false)
      setShowHeaderLoader(false)
    }
  }, [isCaseUpdated])

  useEffect(() => {
    if (!countryListOptions.length && allCaseCountriesData) {
      const countriesList = allCaseCountriesData.map(({ Description, Code }) => ({ value: Code, label: Description }))
      setCountryListOptions(countriesList)
    }
  }, [allCaseCountriesData])

  return (
    <App>
      <Sidebar />
      <Layout>
        <Header subHeader={<CasesSubHeader />} />
        <Content>
          <CaseDetailsWrapper>
            <Container>
              <Tabs
                title="Case Details"
                titleIcon={
                  <TabHeaderIcon>
                    <Image src={PersonsIcon} />
                  </TabHeaderIcon>
                }
                tabs={[
                  {
                    id: 1,
                    name: 'Profile Information',
                    footer: <Button onClick={handleProfileFormSubmitClick}>Save</Button>,
                  },
                  {
                    id: 2,
                    name: 'KYC Overview',
                    footer: <Button onClick={handleKYCFormSubmitClick}>Save</Button>,
                  },
                ]}
                footer={<>Footer</>}
              >
                <TabPane id="1">
                  <CaseDetailsProfileForm
                    customerData={caseDataState}
                    countryListOptions={countryListOptions}
                    formRef={formProfileRef}
                    onSubmit={handleOnFormSubmit}
                    setIsCaseUpdated={setIsCaseUpdated}
                  />
                </TabPane>
                <TabPane id="2">
                  <CaseDetailsKYCForm customerData={caseCustomerData} formRef={formKYCRef} />
                </TabPane>
              </Tabs>
            </Container>
          </CaseDetailsWrapper>
        </Content>
      </Layout>
    </App>
  )
}

// Default Props
Details.defaultProps = {
  form: {},
  actions: {},
}

// Proptypes Validation
Details.propTypes = {
  form: PropTypes.shape({
    countryListOptions: PropTypes.instanceOf(Array),
  }),
  actions: PropTypes.shape({
    setCountryListOptions: PropTypes.func,
    setActiveModule: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(Details)
