import { authAxios } from 'common/utils/axios'

// Endpoint
import { WHITELISTING_ENDPOINTS } from 'common/constants/apiUrls'

export const AssignWhitelistingAddress = async ({ addressOrTransactionId, case_id }) => {
  const response = await authAxios.put(WHITELISTING_ENDPOINTS.ASSIGN_ADDRESS, {
    address_id: addressOrTransactionId,
    case_id,
  })

  const data = response.data.content[0]

  return data
}

export const GetWhitelistingAddressCount = async ({ biz_id, start_range, end_range }) => {
  const response = await authAxios.get(WHITELISTING_ENDPOINTS.ADDRESS_COUNT, {
    params: {
      biz_id,
      start_range,
      end_range,
    },
  })

  const data = response.data.content[0]

  return data
}

export const GetWhitelistingAddressHistory = async ({ biz_id, start_range, end_range }) => {
  const response = await authAxios.get(WHITELISTING_ENDPOINTS.ADDRESS_HISTORY, {
    params: {
      biz_id,
      start_range,
      end_range,
    },
  })

  const data = response.data.content

  return data
}

export const GetWhitelistingAddressReport = async ({ address_id }) => {
  const response = await authAxios.put(WHITELISTING_ENDPOINTS.GET_ADDRESS_REPORT, {
    address_id,
  })

  const data = response.data.content[0]

  return data
}

export const GetWhitelistingPagedTable = async ({
  biz_id,
  start_range,
  end_range,
  sortby,
  sortdir,
  pageindex,
  pagesize,
}) => {
  const response = await authAxios.get(WHITELISTING_ENDPOINTS.PAGED_TABLE_DATA, {
    params: {
      biz_id,
      start_range,
      end_range,
      sortby,
      sortdir,
      pageindex,
      pagesize,
    },
  })

  const data = response.data.content

  return data
}

export const GetWhitelistingRiskBucket = async ({ biz_id, start_range, end_range }) => {
  const response = await authAxios.get(WHITELISTING_ENDPOINTS.RISK_BUCKET, {
    params: {
      biz_id,
      start_range,
      end_range,
    },
  })

  const data = response.data.content

  return data
}

export const GetWhitelistingTransaction = async ({ user_id, transaction }) => {
  const response = await authAxios.get(WHITELISTING_ENDPOINTS.SEARCH_TRANSACTION, {
    params: {
      user_id,
      transaction,
    },
  })

  const data = response.data.content[0]

  return data
}

export const RemoveWhitelistingAddress = async ({ address_id }) => {
  const response = await authAxios.put(WHITELISTING_ENDPOINTS.REMOVE_ADDRESS, {
    address_id,
  })

  const data = response.data.content[0]

  return data
}
