import React from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Assets
import BrandLogo from 'assets/images/brand-logo.png'
import UserIcon from 'assets/images/user.svg'
import UserCircledIcon from 'assets/images/user-icon.svg'
import LogoutIcon from 'assets/images/log-out.svg'
import MenuIcon from 'assets/images/menu-icon.svg'
import TabPaneIcon from 'assets/images/tab-pane.svg'
import NotificationIcon from 'assets/images/notification-bell.svg'

// Contants
import { EXTERNAL_URLS } from 'common/constants/apiUrls'

// Store
import { actions } from 'core/store'

// Hooks
import { useLogout } from 'core/hooks/api'

// Styled Elements
import {
  HeaderWrapper,
  HeaderInner,
  HeaderBrand,
  HeaderBrandLogo,
  HeaderMenuNav,
  HeaderMenuNavList,
  HeaderMenuNavItem,
  HeaderMenuNavLink,
  HeaderRightNav,
  HeaderRightNavList,
  HeaderRightNavListItem,
  HeaderLogoutButton,
  ProfileInfoWrapper,
  ProfileFullName,
  ProfileUserName,
  ProfileUserRole,
  HeaderSideMenuButton,
  HeaderSideMenuIcon,
  NotificationIconImage,
  MenuIconImage,
  UserIconImage,
  UserCircledIconImage,
  LogoutIconImage,
  SubHeaderWrapper,
  SubHeaderContent,
  DropdownItemLink,
} from './Header.elements'

// Views
import {
  ContainerFluid,
  Dropdown,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Image,
  LoaderLine,
} from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

const Header = (props) => {
  // Destructure
  const { ui, actions, subHeader } = props

  // Store States
  const { showHeaderLoader } = ui

  // Store Actions
  const { openAppSidebarDrawer } = actions

  // Hooks
  const { logoutUser } = useLogout()

  // Functions
  const handleLogout = () => {
    logoutUser()
  }

  const handleOpenSidebarDrawer = () => {
    openAppSidebarDrawer()
  }

  return (
    <HeaderWrapper>
      <ContainerFluid>
        <HeaderInner>
          <HeaderBrand>
            <NavLink to="/">
              <HeaderBrandLogo>
                <Image src={BrandLogo} />
              </HeaderBrandLogo>
            </NavLink>
          </HeaderBrand>
          <HeaderMenuNav>
            <HeaderMenuNavList>
              <HeaderMenuNavItem>
                <HeaderMenuNavLink exact activeClassName="active" to="/">
                  Case Management
                </HeaderMenuNavLink>
              </HeaderMenuNavItem>
              <HeaderMenuNavItem>
                <HeaderMenuNavLink exact to="/whitelisting">
                  Address Monitoring
                </HeaderMenuNavLink>
              </HeaderMenuNavItem>
              <HeaderMenuNavItem>
                <HeaderMenuNavLink exact to="/monitoring">
                  Transaction Monitoring
                </HeaderMenuNavLink>
              </HeaderMenuNavItem>
            </HeaderMenuNavList>
          </HeaderMenuNav>
          <HeaderRightNav>
            <HeaderRightNavList>
              <HeaderRightNavListItem>
                <Dropdown direction="down">
                  <DropdownToggle>
                    <NotificationIconImage>
                      <Image src={NotificationIcon} />
                    </NotificationIconImage>
                  </DropdownToggle>
                  <DropdownMenu left>
                    <DropdownItem header>
                      <NavLink to={EXTERNAL_URLS.FEEDBACK}>Send feedback</NavLink>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </HeaderRightNavListItem>
              <HeaderRightNavListItem>
                <UncontrolledDropdown direction="down">
                  <DropdownToggle>
                    <MenuIconImage>
                      <Image src={MenuIcon} />
                    </MenuIconImage>
                  </DropdownToggle>
                  <DropdownMenu left>
                    <DropdownItem header>
                      <NavLink to={EXTERNAL_URLS.FEEDBACK}>Send feedback</NavLink>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </HeaderRightNavListItem>
              <HeaderRightNavListItem>
                <UncontrolledDropdown direction="down">
                  <DropdownToggle>
                    <UserIconImage>
                      <Image src={UserIcon} />
                    </UserIconImage>
                  </DropdownToggle>
                  <DropdownMenu left>
                    <ProfileInfoWrapper>
                      <ProfileFullName>Kenneth Burla</ProfileFullName>
                      <ProfileUserName>kenneth.burla@gmail.com</ProfileUserName>
                      <ProfileUserRole>Developer</ProfileUserRole>
                    </ProfileInfoWrapper>
                    <DropdownItem header>
                      <DropdownItemLink to="/user-profile">
                        <UserCircledIconImage>
                          <Image src={UserCircledIcon} height={20} width={20} />
                        </UserCircledIconImage>
                        Profile
                      </DropdownItemLink>
                    </DropdownItem>
                    <DropdownItem header onClick={handleLogout}>
                      <HeaderLogoutButton onClick={handleLogout}>
                        <LogoutIconImage>
                          <Image src={LogoutIcon} height={20} width={20} />
                        </LogoutIconImage>
                        Logout
                      </HeaderLogoutButton>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </HeaderRightNavListItem>
            </HeaderRightNavList>
          </HeaderRightNav>
          <HeaderSideMenuButton>
            <HeaderSideMenuIcon onClick={handleOpenSidebarDrawer}>
              <Image src={TabPaneIcon} />
            </HeaderSideMenuIcon>
          </HeaderSideMenuButton>
        </HeaderInner>
      </ContainerFluid>
      {subHeader && (
        <SubHeaderWrapper>
          <ContainerFluid>
            <SubHeaderContent>{subHeader}</SubHeaderContent>
          </ContainerFluid>
        </SubHeaderWrapper>
      )}
      <LoaderLine show={showHeaderLoader} />
    </HeaderWrapper>
  )
}

// Default Props
Header.defaultProps = {
  subHeader: '',
  ui: {},
  actions: {},
}

// Proptypes Validation
Header.propTypes = {
  subHeader: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  ui: PropTypes.shape({ showHeaderLoader: PropTypes.bool }),
  actions: PropTypes.shape({ openAppSidebarDrawer: PropTypes.func }),
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
