import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'

// Library Components
import { Layout } from 'antd'

// Store
import { actions } from 'core/store'

// Hooks
import { useFetchCaseCustomer, useFetchCaseAddresses } from 'core/hooks/api'

// Styled Components
import { AddressesWrapper, CaseStatusSummaryWrapper } from './Addresses.elements'

// Views
import { Container, Button } from 'views/components'

import { App, Header, Sidebar, Content, CasesSubHeader, CaseStatusSummary, AddressesTable } from 'views/layouts'

// Map Redux Props
const mapStateToProps = () => ({})
const mapDispatchToProps = (dispatch) => actions(dispatch)

const Addresses = (props) => {
  // Destructure
  const { actions } = props

  // Store Actions
  const { toggleUploadCaseModal, setActiveModule, setActiveCase } = actions

  // Hooks
  const { customerId } = useParams()
  const { caseCustomerData, getCaseCustomer } = useFetchCaseCustomer()
  const { caseAddressesData, getCaseAddresses } = useFetchCaseAddresses()

  // Functions
  const handleOpenUploadCaseModal = () => {
    toggleUploadCaseModal()
  }

  // useEffect
  useEffect(() => {
    setActiveModule('addresses')
  }, [])

  useEffect(() => {
    if (customerId) {
      getCaseCustomer({ customer_id: customerId })
    }
  }, [customerId])

  useEffect(() => {
    if (caseCustomerData) {
      getCaseAddresses({ case_id: caseCustomerData.Case_ID })
      setActiveCase(caseCustomerData.Case_ID)
    }
  }, [caseCustomerData])

  return (
    <App>
      <Sidebar />
      <Layout>
        <Header subHeader={<CasesSubHeader />} />
        <Content>
          <AddressesWrapper>
            <Container>
              <CaseStatusSummaryWrapper>
                {caseCustomerData && (
                  <CaseStatusSummary
                    customerId={caseCustomerData.Customer_ID}
                    total={caseCustomerData.AddressCount}
                    status={caseCustomerData.Case_Status}
                    type="address"
                    caseType={caseCustomerData.Case_Type}
                  />
                )}
              </CaseStatusSummaryWrapper>
            </Container>
            <Container>
              {caseAddressesData && (
                <AddressesTable
                  data={caseAddressesData}
                  headerOptions={
                    <Button variant="outlined" onClick={handleOpenUploadCaseModal}>
                      Import
                    </Button>
                  }
                />
              )}
            </Container>
          </AddressesWrapper>
        </Content>
      </Layout>
    </App>
  )
}

// Default Props
Addresses.defaultProps = {
  actions: {},
}

// Proptypes Validation
Addresses.propTypes = {
  actions: PropTypes.shape({
    toggleUploadCaseModal: PropTypes.func,
    setActiveModule: PropTypes.func,
    setActiveCase: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(Addresses)
