import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { v4 as uuid } from 'uuid'
import moment from 'moment'

// Assets
import UserPlusIcon from 'assets/images/user-plus.svg'
import CrossHairIcon from 'assets/images/crosshair.svg'
import ReportIcon from 'assets/images/report.svg'
import TrashIcon from 'assets/images/trash-2.svg'
// import CopyIcon from 'assets/images/copy.svg'

// Constants
import { DASHED_DATE_FORMAT } from 'common/constants/dateFormat'

// Utils
import { numberWithCommas } from 'common/utils/valueFormat'
import riskScoreColor from 'common/utils/riskScoreColor'
import blockChainName from 'common/utils/blockChainName'

// Store
import { actions } from 'core/store'

// Hooks
import { useFetchCaseDetails, useFetchCaseCustomer } from 'core/hooks/api'

// Styled Elements
import {
  AccordionTableButtonWrapper,
  AccordionTableButtonItem,
  AccordionTablePanelWrapper,
  ExpandedAccordionTableButtonWrapper,
  HashWrapper,
  BlurredHashWrapper,
  DropdownItemInner,
  DropdownItemInnerText,
  UniqueIDLink,
  AddressBalanceWrapper,
} from './AddressesTableItem.elements'

// Views
import {
  Image,
  AccordionTableItem,
  AccordionTableDetails,
  TextBlur,
  NumericText,
  BadgeText,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  LegendText,
} from 'views/components'

// Map Redux Props
const mapStateToProps = () => ({})
const mapDispatchToProps = (dispatch) => actions(dispatch)

const AddressesTableItem = (props) => {
  // Destructure
  const {
    id,
    actions,
    address,
    asset,
    balance,
    balanceUsd,
    riskScore,
    flags,
    timestamp,
    latestActivity,
    incoming,
    incomingUsd,
    outgoing,
    outgoingUsd,
    createdBy,
    caseId,
  } = props

  // Store Actions
  const {
    openAssignToCaseDrawer,
    setActivityToRemove,
    toggleDeleteAddressModal,
    setInitialNodeData,
    setAddressOrTransactionIdToAssign,
    setShowHeaderLoader,
  } = actions

  // States
  const [accordionDetails, setAccordionDetails] = useState()

  // Hooks
  const history = useHistory()
  const { caseDetailsData, getCaseDetails, isCaseDetailsLoading } = useFetchCaseDetails()
  const { caseCustomerData, getCaseCustomer, isCaseCustomerLoading } = useFetchCaseCustomer()

  // Functions
  const handleOpenAssignCaseDrawer = () => {
    setAddressOrTransactionIdToAssign({ type: 'address', id })
    openAssignToCaseDrawer()
  }

  const handleOpenRemoveActivityModal = () => {
    setActivityToRemove({ type: 'address', hash: address })
    toggleDeleteAddressModal()
  }

  const handleOnCopyHash = () => {
    openAssignToCaseDrawer()
  }

  const handleOnInvestigationClick = () => {
    setInitialNodeData({
      type: 'address',
      hash: address,
      asset,
      chain: blockChainName(asset),
      value: balance,
      valueUsd: balanceUsd,
      riskScore,
      color: riskScoreColor(riskScore),
      flags,
      direction: 'incoming',
      timeStamp: latestActivity,
      children: [],
    })

    history.push(`/investigation/${address}`)
  }

  const formatAccordionDetails = ({ caseDetailsData, caseCustomerData }) => {
    const data = {
      itemDetails: {
        headers: ['Latest Activity:', caseDetailsData?.Last_Modified || 'Not Available'],
        data: [
          {
            name: 'Address Risk Score:',
            data: [<LegendText color={riskScoreColor(riskScore)}>{riskScore}</LegendText>],
          },
          {
            name: 'Flags:',
            data: [flags],
          },
          {
            name: 'Virtual Asset Name:',
            data: [asset],
          },
          {
            name: 'Address Balance:',
            data: [
              <AddressBalanceWrapper>
                <NumericText currency={asset}>{numberWithCommas(balance)}</NumericText>&nbsp;/&nbsp;
                <NumericText currency="USD">{numberWithCommas(balanceUsd)}</NumericText>
              </AddressBalanceWrapper>,
            ],
          },
          {
            name: 'Watched Address',
            data: ['No'],
          },
          {
            name: 'Created by:',
            data: [createdBy],
          },
        ],
      },
      totalDetails: {
        headers: ['', 'Incoming', 'Outgoing'],
        data: [
          {
            name: 'Total (Virtual Asset):',
            data: [
              <NumericText currency={asset}>{numberWithCommas(incoming)}</NumericText>,
              <NumericText currency={asset}>{numberWithCommas(outgoing)}</NumericText>,
            ],
          },
          {
            name: 'Total (USD):',
            data: [
              <NumericText currency="USD">{numberWithCommas(incomingUsd)}</NumericText>,
              <NumericText currency="USD">{numberWithCommas(outgoingUsd)}</NumericText>,
            ],
          },
        ],
      },
      accountDetails: caseDetailsData && {
        headers: ['Last Modified', '2021-01-07 07:00:39'],
        data: [
          {
            name: 'Account Risk:',
            data: [
              <LegendText color={riskScoreColor(caseCustomerData.Case_Risk)}>{caseCustomerData.Case_Risk}</LegendText>,
            ],
          },
          {
            name: 'Unique ID:',
            data: [
              <UniqueIDLink to={`/case-management/details/${caseDetailsData.Customer_ID}`}>
                {caseDetailsData.Customer_ID}
              </UniqueIDLink>,
            ],
          },
          {
            name: caseDetailsData.Case_Type === 'Natural Person' ? 'First Name' : 'Company Name',
            data: [
              caseDetailsData.Case_Type === 'Natural Person'
                ? caseDetailsData.First_Name
                : caseDetailsData.Company_Name,
            ],
          },
          {
            name: caseDetailsData.Case_Type === 'Natural Person' ? 'Last Name' : 'Country of Incorporation',
            data: [
              caseDetailsData.Case_Type === 'Natural Person'
                ? caseDetailsData.Last_Name
                : caseDetailsData.Country_Incorporation_Description,
            ],
          },
          {
            name: caseDetailsData.Case_Type === 'Natural Person' ? 'Primary Nationality' : 'Country of Domicile',
            data: [
              caseDetailsData.Case_Type === 'Natural Person'
                ? caseDetailsData.Nationality_Description
                : caseDetailsData.Country_Address_Description,
            ],
          },
          {
            name: 'Linked Addresses',
            data: [caseDetailsData.AddressCount],
          },
          {
            name: 'Linked Transactions',
            data: [caseDetailsData.TxCount],
          },
          {
            name: 'Account Type',
            data: [
              caseDetailsData.Case_Type === 'Natural Person'
                ? 'Natural Person' || 'Individual'
                : 'Legal Person' || 'Entity',
            ],
          },
          {
            name: 'Account Status',
            data: [caseDetailsData.Case_Status],
          },
          {
            name: 'Account Since',
            data: [moment(caseDetailsData.Case_Since).format(DASHED_DATE_FORMAT)],
          },
        ],
      },
    }

    setAccordionDetails(data)
  }

  // useEffect
  useEffect(() => {
    if (caseId.length >= 26) {
      getCaseDetails({ case_id: caseId })
    } else {
      formatAccordionDetails({ caseDetailsData })
    }
  }, [])

  useEffect(() => {
    if (caseDetailsData) {
      getCaseCustomer({ customer_id: caseDetailsData.Customer_ID })
    }
  }, [caseDetailsData])

  useEffect(() => {
    if (caseCustomerData) {
      formatAccordionDetails({ caseDetailsData, caseCustomerData })
    }
  }, [caseCustomerData])

  // useEffect(() => {
  //   if (isCaseDetailsLoading && isCaseCustomerLoading) {
  //     setShowHeaderLoader(true)
  //   } else {
  //     setShowHeaderLoader(false)
  //   }
  // }, [isCaseDetailsLoading, isCaseCustomerLoading])

  return (
    <AccordionTableItem
      key={uuid()}
      button={({ expanded }) => {
        const startHashText = address.substring(0, 5)
        const endHashText = address.slice(-5)

        return !expanded ? (
          <AccordionTableButtonWrapper>
            <AccordionTableButtonItem width={250}>
              <BlurredHashWrapper>
                {startHashText}
                <TextBlur />
                <TextBlur />
                <TextBlur />
                <TextBlur />
                <TextBlur />
                <TextBlur />
                <TextBlur />
                <TextBlur />
                {endHashText}
              </BlurredHashWrapper>
            </AccordionTableButtonItem>
            <AccordionTableButtonItem>{asset}</AccordionTableButtonItem>
            <AccordionTableButtonItem>
              <NumericText currency="USD">{numberWithCommas(balanceUsd)}</NumericText>
            </AccordionTableButtonItem>
            <AccordionTableButtonItem>
              <BadgeText color={riskScoreColor(riskScore)}>{riskScore}</BadgeText>
            </AccordionTableButtonItem>
            <AccordionTableButtonItem>{moment(timestamp).format(DASHED_DATE_FORMAT)}</AccordionTableButtonItem>
          </AccordionTableButtonWrapper>
        ) : (
          <ExpandedAccordionTableButtonWrapper>
            <HashWrapper data-for-toggle="false" data-value={address} onClick={handleOnCopyHash}>
              {address}
            </HashWrapper>
            <UncontrolledDropdown direction="down" data-for-toggle="false">
              <DropdownToggle>
                <Button>Action</Button>
              </DropdownToggle>
              <DropdownMenu left>
                <DropdownItem onClick={handleOpenAssignCaseDrawer}>
                  <DropdownItemInner>
                    <Image src={UserPlusIcon} width={16} height={16} />
                    <DropdownItemInnerText>Assign</DropdownItemInnerText>
                  </DropdownItemInner>
                </DropdownItem>
                <DropdownItem onClick={handleOnInvestigationClick}>
                  <DropdownItemInner>
                    <Image src={CrossHairIcon} width={16} height={16} />
                    <DropdownItemInnerText>Investigate</DropdownItemInnerText>
                  </DropdownItemInner>
                </DropdownItem>
                <DropdownItem>
                  <DropdownItemInner>
                    <Image src={ReportIcon} width={16} height={16} />
                    <DropdownItemInnerText>Report</DropdownItemInnerText>
                  </DropdownItemInner>
                </DropdownItem>
                <DropdownItem onClick={handleOpenRemoveActivityModal}>
                  <DropdownItemInner>
                    <Image src={TrashIcon} width={16} height={16} />
                    <DropdownItemInnerText>Remove</DropdownItemInnerText>
                  </DropdownItemInner>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </ExpandedAccordionTableButtonWrapper>
        )
      }}
      panel={
        <AccordionTablePanelWrapper>
          {accordionDetails && <AccordionTableDetails data={accordionDetails} type="address" />}
        </AccordionTablePanelWrapper>
      }
    />
  )
}

// Default Props
AddressesTableItem.defaultProps = {
  actions: {},
  address: '',
  asset: '',
  balance: 0,
  balanceUsd: 0,
  riskScore: 0,
  latestActivity: '',
  timestamp: '',
  caseId: '',
  flags: '',
  createdBy: '',
  incoming: 0,
  incomingUsd: 0,
  outgoing: 0,
  outgoingUsd: 0,
}

// Proptypes Validation
AddressesTableItem.propTypes = {
  actions: PropTypes.shape({
    openAssignToCaseDrawer: PropTypes.func,
    setActivityToRemove: PropTypes.func,
    toggleDeleteAddressModal: PropTypes.func,
    setInitialNodeData: PropTypes.func,
    setAddressOrTransactionIdToAssign: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
  }),
  id: PropTypes.string,
  address: PropTypes.string,
  asset: PropTypes.string,
  balance: PropTypes.number,
  balanceUsd: PropTypes.number,
  riskScore: PropTypes.number,
  timestamp: PropTypes.string,
  latestActivity: PropTypes.string,
  caseId: PropTypes.string,
  flags: PropTypes.string,
  createdBy: PropTypes.string,
  incoming: PropTypes.number,
  incomingUsd: PropTypes.number,
  outgoing: PropTypes.number,
  outgoingUsd: PropTypes.number,
}

export default connect(mapStateToProps, mapDispatchToProps)(AddressesTableItem)
