import { authAxios } from 'common/utils/axios'

import { CASES_ENDPOINTS } from 'common/constants/apiUrls'

export const CreateCase = async ({
  adverse_media,
  biz_id,
  case_id,
  case_risk,
  case_type,
  comments,
  company_name,
  country_address,
  country_incorporation,
  customer_id,
  date_birth,
  date_incoportation,
  first_name,
  last_name,
  middle_name,
  nationality,
  pep,
  sanction,
  status,
}) => {
  const response = await authAxios.post(CASES_ENDPOINTS.CREATE_CASE, {
    adverse_media,
    biz_id,
    case_id,
    case_risk,
    case_type,
    comments,
    company_name,
    country_address,
    country_incorporation,
    customer_id,
    date_birth,
    date_incoportation,
    first_name,
    last_name,
    middle_name,
    nationality,
    pep,
    sanction,
    status,
  })

  const data = response.data.content[0]

  return data
}

export const GetAllCaseCountries = async () => {
  const response = await authAxios.get(CASES_ENDPOINTS.GET_ALL_COUNTRIES)

  const data = response.data.content

  return data
}

export const GetAllCases = async ({ biz_id, status_id, case_type_id, pagesize, pageindex, sortcol, sortdir }) => {
  const response = await authAxios.get(CASES_ENDPOINTS.READ_ALL_CASES, {
    params: {
      biz_id,
      status_id,
      case_type_id,
      pagesize,
      pageindex,
      sortcol,
      sortdir,
    },
  })

  const data = response.data.content

  return data
}

export const GetCaseActivities = async ({ case_id, start_range, end_range }) => {
  const response = await authAxios.get(CASES_ENDPOINTS.READ_CASE_ACTIVITIES, {
    params: {
      case_id,
      start_range,
      end_range,
    },
  })

  const data = response.data.content

  return data
}

export const GetCaseAddresses = async ({ case_id, start_range, end_range, sortby, sortdir, pageindex, pagesize }) => {
  const response = await authAxios.get(CASES_ENDPOINTS.READ_CASE_ADDRESSES, {
    params: {
      case_id,
      start_range,
      end_range,
      sortby,
      sortdir,
      pageindex,
      pagesize,
    },
  })

  const data = response.data.content

  return data
}

export const GetCaseCustomer = async ({ customer_id }) => {
  const response = await authAxios.get(CASES_ENDPOINTS.READ_CASE_CUSTOMER, {
    params: {
      customer_id,
    },
  })

  const data = response.data.content[0]

  return data
}

export const GetCaseDetails = async ({ case_id }) => {
  const response = await authAxios.get(CASES_ENDPOINTS.READ_CASE_DETAILS, {
    params: {
      case_id,
    },
  })

  const data = response.data.content[0]

  return data
}

export const GetCaseInvestigation = async ({ cipher, type, limit, chain, direction, ...rest }) => {
  const response = await authAxios.get(CASES_ENDPOINTS.INVESTIGATION, {
    params: {
      cipher,
      type,
      limit,
      chain,
      direction,
      ...rest,
    },
  })

  const data = response.data.content

  return data
}

export const GetCaseTransactions = async ({
  case_id,
  start_range,
  end_range,
  sortby,
  sortdir,
  pageindex,
  pagesize,
}) => {
  const response = await authAxios.get(CASES_ENDPOINTS.READ_CASE_TRANSACTIONS, {
    params: {
      case_id,
      start_range,
      end_range,
      sortby,
      sortdir,
      pageindex,
      pagesize,
    },
  })

  const data = response.data.content

  return data
}

export const GetDashboardCaseAddresses = async ({ biz_id, status_id, case_type_id }) => {
  const response = await authAxios.get(CASES_ENDPOINTS.DASHBOARD_CASE_ADDRESSES, {
    params: {
      biz_id,
      status_id,
      case_type_id,
    },
  })

  const data = response.data.content[0]

  return data
}

export const GetDashboardCaseTransactions = async ({ biz_id, status_id, case_type_id }) => {
  const response = await authAxios.get(CASES_ENDPOINTS.DASHBOARD_CASE_TRANSACTIONS, {
    params: {
      biz_id,
      status_id,
      case_type_id,
    },
  })

  const data = response.data.content[0]

  return data
}

export const GetDashboardTotalCases = async ({ biz_id, status_id, case_type_id }) => {
  const response = await authAxios.get(CASES_ENDPOINTS.DASHBOARD_TOTAL_CASES, {
    params: {
      biz_id,
      status_id,
      case_type_id,
    },
  })

  const data = response.data.content[0]

  return data
}

export const UpdateCase = async ({
  business_id,
  business_name,
  customer_id,
  case_id,
  first_name,
  middle_name,
  last_name,
  date_birth,
  company_name,
  date_incorporation,
  comments,
  case_since,
  last_modified,
  nationality,
  nationalityText,
  country_address,
  country_incorporation,
  case_type_id,
  case_type,
  status,
  statusText,
  pep,
  pepText,
  adverse_media,
  adverseMediaText,
  case_risk,
  caseRiskText,
}) => {
  const response = await authAxios.put(CASES_ENDPOINTS.UPDATE_CASE, {
    business_id,
    business_name,
    customer_id,
    case_id,
    first_name,
    middle_name,
    last_name,
    date_birth,
    company_name,
    date_incorporation,
    comments,
    case_since,
    last_modified,
    nationality,
    nationalityText,
    country_address,
    country_incorporation,
    case_type_id,
    case_type,
    status,
    statusText,
    pep,
    pepText,
    adverse_media,
    adverseMediaText,
    case_risk,
    caseRiskText,
  })

  const data = response.data.content[0]

  return data
}
