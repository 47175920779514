import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { connect } from 'react-redux'

// Store
import { actions } from 'core/store'

// Hooks
import { useLogin } from 'core/hooks/api'
import { useLocalStorage } from 'core/hooks/storage'

// Styled Elements
import { LoginFormWrapper, LoginFormLabel, FormInputGroupWrapper, FormInputGroupItem } from './LoginForm.elements'

// Views
import { Form, FormTextField, Button } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

const LoginForm = (props) => {
  // Destructure
  const { actions } = props

  // Store Actions
  const { showAlert } = actions

  // Hooks
  const { loginUser, loginUserData, loginUserError, isLoginUserLoading, isLoginUserSuccess } = useLogin()

  const [isLoggedIn, setIsloggedIn] = useLocalStorage('isLoggedIn', false)
  const [userCredentials] = useLocalStorage('userCredentials', {})

  // Variables
  const initialFormValues = {
    username: '',
    password: '',
  }

  const validationSchema = Yup.object().shape({
    username: Yup.string().email('Invalid Email Format').required('Email is required'),
    password: Yup.string().required('Password is required'),
  })

  // Functions
  const handleOnSubmit = (values) => {
    loginUser(values)
  }

  // useEffect
  useEffect(() => {
    // Checking if user session is expired
    if (isLoggedIn && !userCredentials?.access_token) {
      setIsloggedIn(false)
      showAlert({ type: 'error', message: 'Session Expired' })
    }
  }, [])
  useEffect(() => {
    if (loginUserError) {
      showAlert({ type: 'error', message: loginUserError.response.data.message[0] })
    }
  }, [loginUserError])

  useEffect(() => {
    if (loginUserData) {
      window.location.replace(window.location.origin)
    }
  }, [loginUserData])

  return (
    <LoginFormWrapper>
      <Form initialValues={initialFormValues} validationSchema={validationSchema} onSubmit={handleOnSubmit}>
        <FormInputGroupWrapper>
          <FormInputGroupItem>
            <LoginFormLabel htmlFor="email">Email Address</LoginFormLabel>
            <FormTextField type="email" name="username" />
          </FormInputGroupItem>
          <FormInputGroupItem>
            <LoginFormLabel htmlFor="password">Password</LoginFormLabel>
            <FormTextField type="password" name="password" />
          </FormInputGroupItem>
        </FormInputGroupWrapper>

        <Button fullWidth type="submit">
          {isLoginUserLoading || isLoginUserSuccess ? 'Logging in...' : 'Login'}
        </Button>
      </Form>
    </LoginFormWrapper>
  )
}

// Default Props
LoginForm.defaultProps = {
  actions: {},
}

// Proptypes Validation
LoginForm.propTypes = {
  actions: PropTypes.shape({
    showAlert: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm)
