import { useEffect } from 'react'
import { useMutation } from 'react-query'

// Services
import { Login } from 'services/api/Auth'

// Hooks
import { useLocalStorage } from 'core/hooks/storage'

export default () => {
  const {
    mutate: loginUser,
    data: loginUserData,
    error: loginUserError,
    isLoading: isLoginUserLoading,
    isSuccess: isLoginUserSuccess,
    isError: isLoginUserError,
  } = useMutation(Login)

  const [, setUserCredentials] = useLocalStorage('userCredentials', {})

  // Save User Credentials to LocalStorage
  useEffect(() => {
    if (loginUserData && !isLoginUserError) {
      // Adding expiry date to credentials
      const expDate = new Date();
      expDate.setHours(expDate.getHours() + 8);

      const loginUserDataWithExpDate = {
        ...loginUserData,
        expiry: expDate
      }
      // Using local storage directly as using hooks messes up the login process
      localStorage.setItem('isLoggedIn', true)
      setUserCredentials(loginUserDataWithExpDate)
      window.location.reload()
    }
  }, [loginUserData])

  return { loginUser, loginUserData, loginUserError, isLoginUserLoading, isLoginUserSuccess, isLoginUserError }
}
