import styled from 'styled-components'

// Library Components
import { ModalHeader } from 'reactstrap'

export const ModalHeaderWrapper = styled(ModalHeader)`
  border: none;
`

export const ModalHeaderTitle = styled.div`
  font-size: 18px;
  font-weight: 700;
`

export const ModalHeaderCloseIcon = styled.div`
  cursor: pointer;
`
