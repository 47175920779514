import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  activeModule: '',
  activeCase: '',
  activePage: '',
  activeInvestigationModule: '',
  isAppSidebarDrawerOpen: false,
  isAssignToCaseDrawerOpen: false,
  isCreateIndividualCaseDrawerOpen: false,
  isCreateEntityCaseDrawerOpen: false,
  isDeleteAddressModalOpen: false,
  isChooseKYCUploadModalOpen: false,
  isUploadCaseModalOpen: false,
  showHeaderLoader: false,
  isNewCaseCreated: false,
  isCasesManagementFilterModalOpen: false,
  isWhiteListingFilterModalOpen: false,
}

const store = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setActiveModule: (state, { payload }) => {
      state.activeModule = payload
    },
    setActiveCase: (state, { payload }) => {
      state.activeCase = payload
    },
    setActivePage: (state, { payload }) => {
      state.activePage = payload
    },
    setActiveInvestigationModule: (state, { payload }) => {
      state.activeInvestigationModule = payload
    },
    openAppSidebarDrawer: (state) => {
      state.isAppSidebarDrawerOpen = true
    },
    closeAppSidebarDrawer: (state) => {
      state.isAppSidebarDrawerOpen = false
    },
    openAssignToCaseDrawer: (state) => {
      state.isAssignToCaseDrawerOpen = true
    },
    closeAssignToCaseDrawer: (state) => {
      state.isAssignToCaseDrawerOpen = false
    },
    openCreateIndividualCaseDrawer: (state) => {
      state.isCreateIndividualCaseDrawerOpen = true
    },
    closeCreateIndividualCaseDrawer: (state) => {
      state.isCreateIndividualCaseDrawerOpen = false
    },
    openCreateEntityCaseDrawer: (state) => {
      state.isCreateEntityCaseDrawerOpen = true
    },
    closeCreateEntityCaseDrawer: (state) => {
      state.isCreateEntityCaseDrawerOpen = false
    },
    toggleDeleteAddressModal: (state) => {
      state.isDeleteAddressModalOpen = !state.isDeleteAddressModalOpen
    },
    toggleChooseKYCUploadModal: (state) => {
      state.isChooseKYCUploadModalOpen = !state.isChooseKYCUploadModalOpen
    },
    toggleUploadCaseModal: (state) => {
      state.isUploadCaseModalOpen = !state.isUploadCaseModalOpen
    },
    setShowHeaderLoader: (state, { payload }) => {
      state.showHeaderLoader = payload
    },
    setNewCaseCreated: (state, { payload }) => {
      state.isNewCaseCreated = payload
    },
    toggleCasesManagementFilterModal: (state) => {
      state.isCasesManagementFilterModalOpen = !state.isCasesManagementFilterModalOpen
    },
    toggleWhiteListingFilterModalOpen: (state) => {
      state.isWhiteListingFilterModalOpen = !state.isWhiteListingFilterModalOpen
    },
  },
})

export default store
